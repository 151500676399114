// @refresh reload
import { mount, StartClient } from '@solidjs/start/client';

import { fetchScript } from '~/utils/fetchScript';
import { isNativeApp } from '~/utils/isNativeApp';

if (!isNativeApp()) {
  void fetchScript(import.meta.env.VITE_PLAYER_URL);
}

if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID && import.meta.env.PROD) {
  void fetchScript(
    'https://www.googletagmanager.com/gtag/js?id=' +
      import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
  );
}

mount(() => <StartClient />, document.querySelector('#app') ?? document.body);
