import { MetaProvider } from '@solidjs/meta';
import { A, type RouteSectionProps } from '@solidjs/router';
import 'pollen-css/pollen.css';
import { SolidNProgress } from 'solid-progressbar';
import { Suspense } from 'solid-js';

import '~/code-highlight.scss';
import PartnerList from '~/components/PartnerList';
import styles from '~/components/Root.module.scss';
import ThemeSwitcher from '~/components/ThemeSwitcher';
import { generateRetinaThumbs } from '~/utils/generateThumbs';

export default function Root(props: RouteSectionProps) {
  return (
    <MetaProvider>
      <SolidNProgress stopDelayMs={0} options={{ showSpinner: false }} />
      <h1 class={styles.logo} style={{ 'text-align': 'center' }}>
        <A href="/">
          <img
            src="/assets/logo.png"
            srcset={generateRetinaThumbs(
              `${import.meta.env.VITE_SITE_HOST}/assets/logo.png`,
              'x48',
            )}
            alt="Rádio J-Hero"
            height={48}
          />
        </A>
      </h1>
      <nav>
        <A href="/contato">Contato</A>
      </nav>
      <Suspense>{props.children}</Suspense>
      <PartnerList />
      <ThemeSwitcher />
      <p style={{ 'text-align': 'center' }}>
        Rádio J-Hero v5.0 — Do seu jeito, do seu gosto!
        <br />
        Articles and logo are © 2008–{new Date().getFullYear()} Rádio J-Hero.
        All rights reserved.
        <br />
        All dates are displayed according to{' '}
        <A rel="external" href="https://time.is/Brasília">
          Brasília Time
        </A>
        .<br />
        Icons by{' '}
        <A rel="external" href="https://tabler.io/icons">
          Tabler
        </A>
        . Powered by{' '}
        <A rel="external" href="https://netlify.com/">
          Netlify
        </A>
        .
      </p>
    </MetaProvider>
  );
}
