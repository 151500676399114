import { gql } from '@apollo/client/core';
import groupBy from 'lodash.groupby';
import { cache, createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';

import { client } from '~/utils/graphql';

enum PartnerType {
  TOP,
  LIVE,
  NORMAL,
}

const PARTNERS = gql`
  query Partners {
    partners(options: { sort: [{ title: ASC }] }) {
      type
      title
      link
      picture {
        url
      }
    }
  }
`;

const getPartners = cache(async () => {
  'use server';

  const { data } = await client.query({ query: PARTNERS });

  return groupBy(data.partners, (partner: any) => partner.type);
}, 'partners');

export default function PartnerList() {
  const data = createAsync(() => getPartners());

  return (
    <Suspense>
      <h2>Parceiros</h2>
      <For
        each={Object.values(PartnerType).filter(key => typeof key === 'string')}
        fallback="Ninguém..."
      >
        {key => (
          <Show when={data()?.[key]?.length}>
            <h3>{key}</h3>
            <ul
              style={{
                display: 'flex',
                'flex-wrap': 'wrap',
                gap: 'var(--size-2)',
                'list-style': '""',
                'padding-inline-start': '0',
              }}
            >
              <For each={data()?.[key]}>
                {partner => (
                  <li>
                    <a rel="external" href={partner.link}>
                      <img
                        loading="lazy"
                        width={88}
                        height={31}
                        alt={partner.title}
                        src={partner.picture.url}
                        style={{ display: 'block' }}
                      />
                    </a>
                  </li>
                )}
              </For>
            </ul>
          </Show>
        )}
      </For>
    </Suspense>
  );
}
