import { useCookie } from 'solidjs-hooks';
import {
  getCookie,
  setCookie,
  type CookieSerializeOptions,
  // @ts-expect-error: this works thanks to `vite-plugin-iso-import`
} from 'vinxi/http?server';

const _useCookie = (() => {
  if (!import.meta.env.SSR) {
    return useCookie;
  }

  return (name: string) =>
    [
      () => getCookie(name),
      (value: string, options: CookieSerializeOptions) => {
        setCookie(name, value, options);
      },
    ] as const;
})();

export default _useCookie;
