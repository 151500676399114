import { createEffect } from 'solid-js';

import useCookie from '~/hooks/useCookie';

export default function ThemeSwitcher() {
  const [colorScheme, setColorScheme] = useCookie('colorScheme');

  const changeColorScheme = (colorScheme: string) => {
    setColorScheme(colorScheme, {
      expires: new Date(Date.now() + 360 * 86_400_000),
      secure: true,
    });
    const event = new CustomEvent('colorschemechange', { detail: colorScheme });
    window.dispatchEvent(event);
  };

  createEffect(() => {
    changeColorScheme(colorScheme() ?? 'system');
  });

  return (
    <ul
      style={{
        display: 'flex',
        'padding-inline-start': '0',
        'justify-content': 'center',
        gap: 'var(--size-4)',
        'list-style': "''",
      }}
      onClick={event => {
        if (event.target instanceof HTMLInputElement) {
          changeColorScheme(event.target.value);
        }
      }}
    >
      <li>
        <label>
          <input
            checked={colorScheme() === 'system'}
            type="radio"
            name="colorScheme"
            value="system"
          />
          Tema do sistema
        </label>
      </li>
      <li>
        <label>
          <input
            checked={colorScheme() === 'light'}
            type="radio"
            name="colorScheme"
            value="light"
          />
          Tema claro
        </label>
      </li>
      <li>
        <label>
          <input
            checked={colorScheme() === 'dark'}
            type="radio"
            name="colorScheme"
            value="dark"
          />
          Tema escuro
        </label>
      </li>
    </ul>
  );
}
